import React from 'react'
// import PropTypes from 'prop-types'

import {graphql} from 'gatsby'
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next'
// import { StaticImage } from "gatsby-plugin-image"

import ReactMarkdown from 'react-markdown'

import Seo from '../../components/Seo'
import Layout from "../../components/Layout";

const ProjectTemplate = ({ pageContext: { title }, data }) => {
	const { strapiProject: { description, image }} = data;
	const firstImg = image[0];
	const {t} = useI18next();

  return (
    <Layout>
			<Seo title={title.toUpperCase()} description={description} image={firstImg.localFile.publicURL} />
      <main className="project-template-page">
				<figure>
					<img src={firstImg.localFile.publicURL} alt={title} layout="fixed" height={300} placeholder="blurred" />
					<figcaption>
						<h2>{title}</h2>
					</figcaption>
				</figure>
				<div>
					<ReactMarkdown>{description}</ReactMarkdown>
				</div>
				<div style={{padding: '1rem'}}>
					<Link to="/projects" className="btn center-btn">
						<span role="img" aria-label="see projects" aria-labelledby="see projects">🚀</span>
						&nbsp;{t("Todos mis proyectos")}
					</Link>
				</div>
			</main>
    </Layout>
  )
}

export const query = graphql`
	query($title:String, $language: String!) {
		strapiProject(title: {eq: $title}) {
			description
			title
			image {
				localFile {
					publicURL
				}
			}
		}
		locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
	}
`

export default ProjectTemplate